<template>
  <div>
    <template v-if="loadedPlan">
      <div
        v-if="!userHasActivePlan && !authUserIsAdmin()"
        class="alert alert-warning"
        role="alert"
      >
        Su plan actual solo le permite ver un estado y municipio, cambie de plan
        para visualizar para visualizar todos los estados y municipios
        disponibles.
      </div>
    </template>
    <div class="row">
      <div class="col-md-4 col-lg-3">
        <div class="mb-3">
          <label for="state" class="form-label">Estado:</label>
          <select
            @change="addParamToURL('state_id', this.selectedStateId)"
            id="state"
            class="form-select"
            v-model="selectedStateId"
            required
          >
            <option value="all">Todos</option>
            <template v-for="estado in estados" :key="estado.id">
              <option
                v-if="
                  userHasActivePlan ||
                  authUser.state_id === estado.id ||
                  authUserIsAdmin()
                "
                :value="estado.id"
              >
                {{ estado.name }}
              </option>
            </template>
          </select>
        </div>
      </div>
      <!-- Año -->
      <div class="col-md-4 col-lg-3">
        <div class="mb-3">
          <label for="year" class="form-label">Año:</label>
          <select
            @change="addParamToURL('year', this.selectedYear)"
            id="year"
            class="form-select"
            v-model="selectedYear"
            required
          >
            <option value="all">Todos</option>
            <option v-for="year in years" :value="year" :key="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>
      <!-- Mes -->
      <div class="col-md-4 col-lg-3">
        <div class="mb-3">
          <label for="month" class="form-label">Mes:</label>
          <select
            @change="addParamToURL('month', this.selectedMonth)"
            id="month"
            class="form-select"
            v-model="selectedMonth"
            required
          >
            <option value="all">Todos</option>
            <option v-for="month in months" :value="month.id" :key="month.id">
              {{ month.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4 col-lg-3">
        <label for="month" class="form-label">Título:</label>
        <div class="mb-3">
          <input
            v-model="searchTitle"
            type="text"
            class="form-control"
            @keyup="filterByTitle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { all as allEstados } from "@/services/states";
// import { subscriptions } from "@/services/users";
import { authUser, authUserIsAdmin } from "@/services/auth";
import { userPlanIsActive } from "@/services/users";

export default {
  data() {
    return {
      estados: [],
      years: [],
      // plan: {},
      userHasActivePlan: false,
      loadedPlan: false,
      authUser: authUser(),
      months: [
        { id: 1, name: "Enero" },
        { id: 2, name: "Febrero" },
        { id: 3, name: "Marzo" },
        { id: 4, name: "Abril" },
        { id: 5, name: "Mayo" },
        { id: 6, name: "Junio" },
        { id: 7, name: "Julio" },
        { id: 8, name: "Agosto" },
        { id: 9, name: "Septiembre" },
        { id: 10, name: "Octubre" },
        { id: 11, name: "Noviembre" },
        { id: 12, name: "Diciembre" },
      ],
      selectedStateId: this.$route.query.stateId || "all",
      selectedYear: this.$route.query.year || "all",
      selectedMonth: this.$route.query.month || "all",
      searchTitle: this.$route.query.title || "",
      authUserIsAdmin: authUserIsAdmin,
    };
  },
  mounted() {
    this.getEstados();
    this.generateYears();
    this.hasActivePlan();
    // this.getSubscriptions();
    if (!this.authUser.city_id) {
      this.$router.push("/ayuda-matriz");
    }
  },
  methods: {
    addParamToURL(filterName, filterValue) {
      let newParam = {};
      filterValue !== "all"
        ? (newParam = { [filterName]: filterValue })
        : (newParam = { [filterName]: "" });
      const currentParams = this.$route.query;
      this.$router
        .push({ query: { ...currentParams, ...newParam } })
        .then(() => {
          this.$emit("search");
        });
    },
    filterByTitle() {
      this.addParamToURL("title", this.searchTitle);
    },
    generateYears() {
      for (let i = 2022; i < 2030; i++) {
        this.years.push(i);
      }
    },
    getEstados() {
      allEstados()
        .then((response) => {
          this.estados = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los estados");
        });
    },
    async hasActivePlan() {
      this.userHasActivePlan = await userPlanIsActive();
      this.filterByUserCityOrState();
      this.loadedPlan = true;
    },
    /*getSubscriptions() {
      subscriptions()
        .then((response) => {
          this.plan = response.data;
          this.loadedPlan = true;
          this.filterByUserCityOrState();
          this.loader = false;
        })
        .catch(() => alert("No pudimos cargar tu plan"));
    },*/
    filterByUserCityOrState() {
      // only filter if user does not have plan
      let filterState = this.authUser.state_id;
      let filterCity = this.authUser.city_id;
      if (this.userHasActivePlan || authUserIsAdmin()) {
        filterState = "";
        filterCity = "";
      }
      const routePath = this.$route.path;
      if (routePath.includes("/publicaciones-estatales")) {
        this.addParamToURL("state_id", filterState);
      } else if (routePath.includes("/publicaciones-municipales")) {
        this.addParamToURL("city_id", filterCity);
      }
    },
  },
};
</script>
